import React, { FunctionComponent } from "react";
import * as Styled from "./StudentFiles.styled";
import { Button } from "carbon-components-react";
import { Application } from "../../types/Application";
import { HOST } from "../../config";

type Props = {
  application?: Application;
  fontColor?: string;
};

const StudentFiles: FunctionComponent<Props> = ({ application, fontColor }) => {
  const API_URL = (): string => {
    if (HOST === "https://dev-api.olimpiadafilozoficzna.edu.pl/api") {
      return HOST?.replace("/api", "/");
    }

    if (HOST === "https://api2.olimpiadafilozoficzna.edu.pl/api") {
      return HOST?.slice(0, -3);
    }

    return "";
  };
  return (
    <>
      {" "}
      {application && application.files.length > 0 && (
        <Styled.FilesWrapper>
          {application.files.map((file, idx) => (
            <Styled.FileWrapper key={idx}>
              <Styled.FileName color={fontColor}>
                - {file["contentUrl"].replace("uploads", "")}
              </Styled.FileName>
              <Styled.Date color={fontColor}>
                {new Date(file["createdAt"]).toLocaleDateString("pl-PL", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                })}
                {idx === 0 && (
                  <Styled.ActualFile>Aktualny plik do oceny</Styled.ActualFile>
                )}
              </Styled.Date>

              <Button
                href={`${API_URL()}${file["contentUrl"]}`}
                download
                target="_blank"
              >
                Pobierz
              </Button>
            </Styled.FileWrapper>
          ))}
        </Styled.FilesWrapper>
      )}
    </>
  );
};

export default StudentFiles;
